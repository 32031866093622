<template>
  <el-dropdown trigger="click" @command="changeLanguage">
    <div class="lang_change_btn no_select">
      <img class="langicon" src="@/assets/img/index/langicon.png">
      {{$t('nav.切换语言')}}
    </div>
    <el-dropdown-menu class="no_select" slot="dropdown">
      <el-dropdown-item command="zh" :disabled="'zh'=== $i18n.locale ">中</el-dropdown-item>
      <el-dropdown-item command="en" :disabled="'en'=== $i18n.locale ">英</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  methods: {
    changeLanguage (lang) {
      localStorage.setItem('language', lang)
      this.$i18n.locale = lang // 设置给本地的i18n插件
      // this.$message.success('切换多语言成功')
      this.$notify({
        title: this.$i18n.locale === 'zh' ? '成功' : 'success',
        message: this.$t('nav.切换语言提示'),
        type: 'success',
        offset: 60
      });
    }
  }
}
</script>
<style lang="less" scoped>
.el-dropdown {
  position: relative;
  top: 3px;
  margin-left: 20px;
}
.lang_change_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 86px;
  height: 27px;
  background: #f0f3fa;
  border-radius: 2px;
  color: #696969;
  font-size: 12px;
  cursor: pointer;
}
.langicon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
</style>