import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/index.vue'
Vue.use(VueRouter)

const routes = [
  // 首页
  {
    path: '/',
    name: 'HOME',
    component: Home
  },
  // 资讯列表
  {
    path: '/newsList',
    name: 'NEWS',
    component: () => import('@/views/newsList/index.vue')
  },
  {
    path: '/newsDetails',
    name: 'NEWSDETAILS',
    component: () => import('@/views/newsDetails/index.vue')
  },
  // 招聘
  {
    path: '/recruitInfo',
    name: 'RECRUITINFO',
    component: () => import('@/views/recruitInfo/index.vue')
  },
  {
    path: '/messagePdf',
    name: 'messagePdf',
    component: () => import('@/views/messagePdf/index.vue')
  }

]

const router = new VueRouter({
  routes,
  // 页面跳转让滚动条回到顶部
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
