import { infoWebRequest } from '@/utils/request.js'

// 获取资讯列表
export function getNewsList (data) {
  return infoWebRequest({
    url: '/news/getLatestNewsByCategory',
    method: 'POST',
    data
  })
}
// 获取资讯详情
export function getNewsById (data) {
  return infoWebRequest({
    url: '/news/getNewsById',
    method: 'POST',
    data
  })
}

//反馈接口 81b2e61a4a2740919c6cd59ea5f1d7e5|1000000 超级权限
export function contactUs (data) {
  return infoWebRequest({
    url: '/user/feedback',
    method: 'POST',
    data,
    headers: {
      token: '81b2e61a4a2740919c6cd59ea5f1d7e5|1000000'
    }
  })
}