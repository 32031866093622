<template>
  <div class="title_box" :style="{'margin-bottom': bottom + 'px'}">
    <p>{{title}}</p>
    <p class="Longwood">—— Longwood ——</p>
    <i v-if="isShow" @click="onIconClick">{{btnText}}</i>
  </div>
</template>
<script>
export default {
  props: {
    // 标题
    title: {
      type: String,
      default: ''
    },
    // 下外边距
    bottom: {
      type: String,
      default: '40'
    },
    // 更多按钮状态
    isShow: {
      type: Boolean,
      default: false
    },
    btnText: {
      type: String,
      default: '更多>>'
    }
  },
  data () {
    return {

    }
  },
  methods: {
    onIconClick () {
      this.$emit('onIconClick')
    }
  }
}
</script>
<style lang="less" scoped>
.title_box {
  position: relative;
  width: 100%;
  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }
  .Longwood {
    font-size: 12px;
    color: #616161;
  }
  i {
    position: absolute;
    bottom: -26px;
    right: 78px;
    cursor: pointer;
    font-size: 14px;
    &:hover {
      // bottom: -24px;
      padding-bottom: 2px;
      font-weight: 600;
    }
  }
}
</style>
