<template>
  <div id="app">
    <!-- 头 -->
    <div class="index_title">
      <div class="title_contnt_log_box no_select">
        <img @click="goHome" class="index_logo" src="@/assets/img/index/index_log.png" alt="">
        <Nav ref="navComponent" />
      </div>

    </div>
    <router-view />
    <div class="index_foot">
      <div class="foot_content_box">
        <p>{{$t('版权所有')}}</p>
        <p>Privacy Policy &nbsp;&nbsp;&nbsp;&nbsp;and Conditions</p>
        <p><a href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备2024044988号-1</a></p>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from '@/components/navTitle.vue'
export default {
  components: {
    Nav
  },
  data () {
    return {

    }
  },
  methods: {
    isMobile () {
      const userAgentInfo = navigator.userAgent;
      const mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
      let mobile_flag = false;
      //根据userAgent判断是否是手机
      for (let v = 0; v < mobileAgents.length; v++) {
        if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
          mobile_flag = true;
          break;
        }
      }
      const screen_width = window.screen.width;
      const screen_height = window.screen.height;
      //根据屏幕分辨率判断是否是手机
      if (screen_width < 500 && screen_height < 800) {
        mobile_flag = true;
      }
      return mobile_flag;
    },
    goHome () {
      if (this.$route.path === '/') return
      this.$router.push('/')
      this.$refs.navComponent.isNavId = 0
    }
  },
  watch: {
    $route (to, from) {
      if (to.path === '/recruitInfo') {
        this.$refs.navComponent.isNavId = 0
      }
    }
  },
  created () {
    if (this.isMobile()) {
      document.body.style.setProperty('--width', '100%');

    }
  }
}
</script>
<style lang="less" scoped>
// html,
// body,
// #app {
//   width: 100%;
//   height: 100%;
// }
// #app {
//   padding-top: 76px;
//   // height: 200vh;
// }
.index_title {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100vw;
  padding: 0 20px;
  height: 76px;
  background-color: #fff;
  .title_contnt_log_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: var(--width);
    height: 100%;
    margin: 0 auto;
    .index_logo {
      width: 168px;
      height: 52px;
      cursor: pointer;
    }
  }
}
.index_foot {
  width: 100%;
  height: 115px;
  background-color: #000;
  .foot_content_box {
    height: 100%;
    padding: 37px 0;
    p {
      text-align: center;
      color: #fff;
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 0px;
      }
      a {
        color: #fff;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
