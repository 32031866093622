export default {
  nav: {
    '关于我们': 'About Us',
    '成果展示': 'Our Focus',
    '加入我们': 'Careers',
    '联系我们': 'Contact Us',
    '最新资讯': 'News',
    '团队': 'Team',
    '切换语言': '切换语言',
    '切换语言提示': 'Successfully switched to English',
    '行业新闻': 'Industry news',
    '通知公告': 'Notice'
  }
}