import axios from 'axios'
const infoBaseURL = 'https://api.infox-med.com'
const instance = axios.create({
  baseURL: infoBaseURL,
  // 请求超过这么多时间还没有返回就报错
  timeout: 10000 * 60 // request timeout
})

// 响应拦截器
instance.interceptors.response.use(function (response) {
  return response.data
}, function (error) {
  return Promise.reject(error)
})

export function infoWebRequest (options) {
  return instance({
    method: options.method || 'GET',
    url: options.url,
    data: options.data,
    params: options.params,
    headers: options.headers
  })
}


const yuanSenbaseURL = 'https://api.infox-med.com'
const yuanSenRequest = axios.create({
  baseURL: yuanSenbaseURL,
  // 请求超过这么多时间还没有返回就报错
  timeout: 10000 * 60 // request timeout
})

// 响应拦截器
yuanSenRequest.interceptors.response.use(function (response) {
  return response.data
}, function (error) {
  return Promise.reject(error)
})

export function yuanSenWebRequest (options) {
  return yuanSenRequest({
    method: options.method || 'GET',
    url: options.url,
    data: options.data,
    params: options.params,
    headers: options.headers
  })
}