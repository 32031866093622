import Vue from 'vue' // 引入Vue
import VueI18n from 'vue-i18n' // 引入国际化的插件包
import elementEN from 'element-ui/lib/locale/lang/en' // 引入饿了么的英文包
import elementZH from 'element-ui/lib/locale/lang/zh-CN' // 引入饿了么的中文包
import homeEn from './home/home_en.js'
import homeZh from './home/home_zh.js'
import navEn from './nav/nav_en.js'
import navZh from './nav/nav_zh.js'
import recruitZh from './recruit/recruit_zh.js'
import recruitEn from './recruit/recruit_en.js'
Vue.use(VueI18n) // 全局注册国际化包

// 创建国际化插件的实例
export default new VueI18n({
  // 指定语言类型
  locale: localStorage.getItem('language') || 'en', // 从cookie中获取语言类型 获取不到就是中文
  messages: {
    en: {
      ...elementEN, // 将饿了么的英文语言包引入
      ...homeEn, // 首页英文语言包
      ...navEn, // 导航英文语言包
      // 招聘信息模块英文包
      招聘: {
        ...recruitEn
      }
    },
    zh: {
      ...elementZH, // 将饿了么的中文语言包引入
      ...homeZh, // 首页中文语言包
      ...navZh,// 导航中文语言包
      // 招聘信息模块中文包
      招聘: {
        ...recruitZh
      }
    }
  }
})
