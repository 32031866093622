export default {
  nav: {
    '关于我们': '关于我们',
    '成果展示': '研发展示',
    '加入我们': '加入我们',
    '联系我们': '联系我们',
    '最新资讯': '最新资讯',
    '团队': '团队',
    '切换语言': 'language',
    '切换语言提示': '已成功切换为中文',
    '行业新闻': '行业新闻',
    '通知公告': '通知公告'
  }
}