<template>
  <div class="swiper_box">
    <swiper ref="mySwiper" class="swiper" :options="swiperOption">
      <swiper-slide v-for="(item, i) in imgList" :key="i">
        <img class="swiper_slide_img" :src="item">
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <!-- 左箭头 -->
    <div class="button-prev button" slot="button-prev">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 350 160 90">
        <g id="arrow-svg-home">
          <g id="circ" class="cls-1">
            <circle cx="42" cy="42" r="40" class="cls-4"></circle>
          </g>
          <g id="arrow">
            <path id="arrow-trg" d="M.983,6.929,4.447,3.464.983,0,0,.983,2.482,3.464,0,5.946Z">
            </path>
          </g>
          <path id="line" d="M100,0H0" class="cls-3"></path>
        </g>
      </svg>
    </div>
    <!-- 右箭头 -->
    <div class="button-next button">

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 350 160 90">
        <g id="arrow-svg-home">
          <g id="circ" class="cls-1">
            <circle cx="42" cy="42" r="40" class="cls-4"></circle>
          </g>
          <g id="arrow">
            <!-- <path id="arrow-trg" d="M.983,6.929,4.447,3.464.983,0,0,.983,2.482,3.464,0,5.946Z" class="cls-2"></path> -->
            <path id="arrow-trg" d="M.983,6.929,4.447,3.464.983,0,0,.983,2.482,3.464,0,5.946Z"
                  class="cls-2"></path>
          </g>
          <path id="line" d="M100,0H0" class="cls-3"></path>
        </g>
      </svg>
    </div>
  </div>

</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  props: {
    swiperOption: {
      type: Object,
      default: () => ({})
    },
    imgList: {
      type: Array,
      default: () => ([])
    },
  },

  components: {
    Swiper,
    SwiperSlide
  },
  methods: {

  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  },
  mounted () {
    // console.log(this.swiper);
  },
}
</script>
<style lang="less" scoped>
.swiper_box {
  position: relative;
  width: 100%;
  height: 100%;
}
.swiper {
  width: 100%;
  height: 100%;
  --swiper-theme-color: #00ff33;
  // transform: matrix();
}
.swiper-slide {
  // background-color: pink;
}
.swiper_slide_img {
  display: inline-block;
  width: 100%;
  height: 100%;
  transform: scale(0.8);
  transition: all 0.7s;
}
.swiper-slide-active img,
.swiper-slide-duplicate-active img {
  transform: scale(1);
}
.button-prev,
.button-next {
  display: flex;
  align-items: center;
  transition: 0.5s;
  outline: none;
  position: absolute;
  width: 60px;
  height: 40px;
  z-index: 10;
  bottom: 0px;
  top: 0;
  margin: auto 0;
  // background-color: rgba(0, 0, 0, 0.5);
  // transform: translateY(-34px);
  cursor: pointer;
}

.button-prev {
  left: 25px;
}

.button-next {
  right: 25px;
}

#arrow-svg-home {
  transform: translateY(353px);
}

.button-next #arrow-svg-home {
  transform: translateY(353px) rotateY(180deg);
  transform-origin: 80px 0px 0px;
}

svg {
  transition: 0.5s;
}

.cls-1 {
  transition: 0.5s;
  transform-origin: -20px 40px;
  opacity: 1;
}
.cls-2 {
  stroke-width: 4px;
}
.cls-4 {
  transition: 0.5s;
  stroke-width: 5px;
  stroke: #fff;
  fill: none;
  stroke-dasharray: 7;
  stroke-dashoffset: 1;
  // opacity: 0.4;
  transform-origin: 0px 0px 0px;
}
.cls-3 {
  stroke-width: 4px;
}
#arrow-trg {
  transition: 0.5s;
  fill: #fff;
  transform: rotateY(180deg) translate(-47px, 39px);
}

#line {
  transition: 0.5s;
  stroke: #fff;
  transform: translate(44px, 42px);
}

.button-prev:not(.disabled):hover svg {
  transform: translateX(-15px);
}

.button-next:not(.disabled):hover svg {
  transform: translateX(15px);
}

.button:not(.disabled):hover .cls-1 {
  transform: scale(1.1);
}

.button:not(.disabled):hover .cls-4 {
  stroke-dasharray: 7;
  stroke-dashoffset: 100;
  opacity: 1;
}

.button:not(.disabled):hover #arrow-trg {
  transform: rotateY(180deg) translate(-44px, 39px);
}

.button:not(.disabled):hover #line {
  transform: translate(39px, 42px) scaleX(0.45);
}
</style>