<template>
  <div class="nav_box">
    <el-menu class="el-menu-demo no_select" mode="horizontal" @select="handleSelect"
      text-color="#000">
      <component v-for="item in navList" :key="item.id"
        :is="item.children? 'el-submenu':'el-menu-item'" :index="item.domId">
        <template v-if="item.children">
          <template slot="title">{{$t('nav.'+item.navText)}}</template>
          <el-menu-item class="no_select" v-for="children in item.children" :key="children.id"
            :index="children.domId">{{$t('nav.'+children.navText)}}</el-menu-item>
        </template>
        <template v-else>
          <span>{{$t('nav.'+item.navText)}}</span>
        </template>
      </component>
    </el-menu>
    <!-- 多语言切换组件 -->
    <LangChange />
  </div>
</template>
<script>
import LangChange from './lang/index.vue'
export default {
  components: {
    LangChange
  },
  data () {
    return {
      navList: [
        {
          id: '1',
          navText: '关于我们',
          domId: '',
          children: [
            {
              id: '1-1',
              navText: '关于我们',
              domId: '#aboutus_box'
            },
            // {
            //   id: '1-2',
            //   navText: '团队',
            //   domId: '#user_content_box'
            // },
            {
              id: '1-3',
              navText: '联系我们',
              domId: '#contact_us_box'
            },
          ]
        },
        {
          id: '5',
          navText: '成果展示',
          domId: '#focus_content_box'
        },
        {
          id: '4',
          navText: '加入我们',
          domId: '#news_joinus_content_box'
        },
        {
          id: '3',
          navText: '最新资讯',
          domId: '1',
          children: [
            {
              id: '3-1',
              navText: '行业新闻',
              domId: '/newsList',
            },
            {
              id: '3-2',
              navText: '通知公告',
              domId: '/messagePdf',
            }
          ]
        },


      ],
      // 选中导航id
      isNavId: '0'
    }
  },
  methods: {
    goscroll (domId) {
      this.$nextTick(() => {
        document.querySelector(domId).scrollIntoView({
          block: 'end',
          behavior: 'smooth'
        })
      })
    },
    // 导航激活事件
    handleSelect (key) {
      const mapObj = {
        goPage: (key) => {
          if (this.$route.path !== key) this.$router.push(key)
        },
        default: () => {
          if (this.$route.path !== '/') {
            this.$router.push('/')
          }
          this.goscroll(key)
        }
      }
      const isGoPage = ['/newsList', '/messagePdf'].includes(key)
      isGoPage ? mapObj.goPage(key) : mapObj.default()
    }
  }
}
</script>
<style lang="less" scoped>
.nav_box {
  display: flex;
  align-items: center;
  // 新导航样式
  .el-menu {
    border: 0;
    .el-menu-item {
      font-size: 16px;
      height: 50px;
      font-weight: 400;
      line-height: 50px;
      &:hover {
        top: -1px;
      }
    }
  }
  /deep/ .el-submenu__title {
    height: 50px !important;
    font-size: 16px;
  }
  /deep/.is-active {
    border-color: #499e39 !important;
    .el-submenu__title {
      border-color: #499e39 !important;
    }
  }
  /deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
    line-height: 52px;
  }
}
</style>