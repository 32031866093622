<template>
  <div class="home scrollbar">

    <div class="home_bg_box">
      <img class="index_zhu" v-show="isImg" @load="onImgLoad" src="@/assets/img/index/index_zhu.png"
        alt="">
      <img class="index_zhu" v-show="!isImg" src="@/assets/img/index/index_zhu_map.jpg" alt="">
      <div ref="bg_content_box" class="bg_content_box">
        <!-- <p>CREATING CURES AND </p>
        <p>VALUES TO PATIENTS </p> -->
        <p>{{$t('标语1')}}</p>
        <p>{{$t('标语2')}}</p>
        <i></i>
        <span>{{$t('副标语')}}</span>
        <!-- <span>We're dedicated to innovation!</span> -->
      </div>
    </div>

    <div class="aboutus_box" id="aboutus_box">
      <div class="aboutus_content_box">
        <moduleTitle :title="$t('关于我们')" />
        <div class="aboutus_content">
          <div class="aboutus_content_left">
            <mySwiper :swiperOption="swiperOption" :imgList="imgList" />
          </div>
          <div class="aboutus_content_right">
            <h3>{{$t('关于远森制药')}}</h3>
            <p :class="{'aboutus_lang_zh': $i18n.locale === 'zh'}" class="first_child">
              {{$t('公司简介')}}
            </p>
            <p :class="{'aboutus_lang_zh': $i18n.locale === 'zh'}">
              {{$t('公司介绍')}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="focus_box">
      <div id="focus_content_box" class="focus_content_box">
        <moduleTitle :title="$t('成果展示')" />

        <div class="focus_content">
          <ul class="focus_content_list">
            <li class="list_item padding">
              <div class="list_content">
                <h4>{{$t('专注于')}}</h4>
                <ul class="text_list">
                  <li>{{$t('专注列表1')}}</li>
                  <li>{{$t('专注列表2')}}</li>
                  <li>{{$t('专注列表3')}}</li>
                </ul>
              </div>

            </li>
            <li class="list_item">
              <img src="@/assets/img/index/focus_1.png">
            </li>
            <li class="list_item">
              <img src="@/assets/img/index/focus_2.png">
            </li>
            <li class="list_item">
              <div class="list_content_last">
                <h4>{{$t('药物靶向')}}</h4>
                <ul>
                  <li>{{$t('药物列表1')}}</li>
                  <li>{{$t('药物列表2')}}</li>
                  <li>{{$t('药物列表3')}}</li>
                  <li>{{$t('药物列表4')}}</li>
                  <li>{{$t('药物列表5')}}</li>
                </ul>
              </div>
            </li>
          </ul>
          <div class="focus_content_foot">
            <div class="foot_box">
              <h4>{{$t('眼科学')}}</h4>
              <p :class="{'foot_lang_zh':$i18n.locale === 'zh' }">{{$t('成果段落1')}}</p>
              <p :class="{'foot_lang_zh':$i18n.locale === 'zh' }">{{$t('成果段落2')}}</p>
              <p :class="{'foot_lang_zh':$i18n.locale === 'zh' }">{{$t('成果段落3')}} </p>
              <p :class="{'foot_lang_zh':$i18n.locale === 'zh' }">{{$t('成果段落4')}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="user_box" ref="user_box" v-if="false">
      <div id="user_content_box" class="user_content_box">

        <moduleTitle :title="$t('管理层')" />

        <ul ref="listBox" class="user_content_list">
          <li v-for="item in userInfoList" :key="item.id" @click="onDisplayDetails(item)"
            class="hand">
            <!-- <img :src="require('../../assets/img/index/user'+item.id+'.png')"> -->
            <img :src="item.imgUrl">
            <p class="user_name">{{ $t(item.name)}}
              <span>{{$t(item.degree)}}</span>
            </p>
            <p class="userlist_itemtitle">{{ $t(item.title)}}</p>
            <i class="iconfont icon-bianzu"></i>
          </li>
        </ul>
      </div>
    </div>

    <div class="strategy_box">
      <div class="strategy_content_box">
        <moduleTitle :title="$t('商业战略模块')" />
        <div class="strategy_content">
          <div class="strategy_content_left">
            <h4 class="bottom">{{$t('商业模式')}}</h4>
            <ul class="strategy_content_left_list">
              <li class="bottom">
                {{$t('商业列表1')}}
              </li>
              <li class="bottom">
                {{$t('商业列表2')}}
              </li>
              <li class="bottom">
                {{$t('商业列表3')}}
              </li>
              <li class="bottom">
                {{$t('商业列表4')}}
              </li>
            </ul>
          </div>
          <div class="strategy_content_right">
            <h4> {{$t('战略规划')}}</h4>
            <p>{{$t('战略列表1')}}</p>
            <p>{{$t('战略列表2')}} </p>
          </div>
        </div>
      </div>
    </div>

    <div class="our_culture">
      <div class="our_culture_content">
        <moduleTitle :title="$t('企业文化')" />
        <div class="our_culture_content_box">
          <div class="our_culture_content_left">
            <!-- <img src="../../assets/img/index/culture01.jpg" alt=""> -->
            <div class="my_swiper_box">
              <mySwiper :swiperOption="swiperOption" :imgList="ourCultureImgList" />
            </div>
            <!-- <p>{{$t('诚信创新')}}</p> -->
          </div>
          <div class="our_culture_content_right">
            <img src="../../assets/img/index/culture02.jpg" alt="">
            <!-- <p>{{$t('责任效率')}}</p> -->
          </div>
        </div>
      </div>
    </div>

    <div class="news_joinus_box">
      <div id="news_joinus_content_box" class="news_joinus_content_box">
        <div class="news_box">
          <moduleTitle :title="$t('最新资讯')" :isShow="true" @onIconClick="goNewsListPage" />
          <ul>
            <li v-for="item in newsList" :key="item.id">
              <h4 @click="goNewsDetails(item.id)">{{item.title}}</h4>
              <p>{{item.subtitle}}</p>
            </li>
          </ul>
        </div>
        <div class="joinus_box">
          <moduleTitle :title="$t('加入我们')" :isShow="true" btnText="详情>>"
            @onIconClick="goRecruitInfoPage" />
          <ul>
            <li v-for="(item,i) in joinUsList" :key="i">
              <h4 @click="goRecruitInfoPage(i)">{{$t(item.position)}}</h4>
              <div class="Job_profile">
                <div class="tab_list">
                  <!-- <span v-for="(tab,i) in item.tabs" :key="i">{{tab}}</span> -->
                  {{item.tabs}}
                </div>
                <!-- <p>{{item.salary}}</p> -->
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="contact_us_box" id="contact_us_box">
      <div class="contact_us_content_box">

        <moduleTitle :title="$t('联系我们')" />

        <div class="contact_us_content">
          <div class="contact_us_content_left">
            <h4>{{$t('写信')}}</h4>
            <input type="text" v-model="form.name" placeholder="name">
            <input type="text" v-model="form.Email" placeholder="Email">
            <el-input type="textarea" :rows="6" resize="none" placeholder="Message"
              v-model="form.message">
            </el-input>
            <el-button @click="onSubmit" type="info" :loading="submitLoading">{{$t('写信提交')}}
            </el-button>
            <p class="explain_text">{{$t('隐私说明')}}</p>
          </div>
          <div class="contact_us_content_right">
            <h4>{{$t('公司名字')}}</h4>
            <p class="address_text">

              {{$t('公司地址')}}
            </p>
            <p class="email">
              hrm@longwoodtech.com
            </p>
            <h4>{{$t('工作时间')}}</h4>
            <p>{{$t('工作时间详情')}}</p>
            <p>{{$t('公司电话')}}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <iframe src="./map/index.html" frameborder="0" width="100%" height="462px" scrolling="no" seamless></iframe> -->
    <myDialog :isShow.sync="isShow" width="1000px" height="326px" :origin="{x:'50%',y:'-50%'}">
      <div class="user_info_box">
        <div class="info_left">
          <img class="user_headImg" :src="personageInfo.imgUrl">
          <p class="user_title">{{$t(personageInfo.name)}}
            <span>{{$t(personageInfo.degree)}}</span>
          </p>
          <p class="user_title">{{$t(personageInfo.title)}}</p>
        </div>
        <div class="info_right">
          <p class="user_briefIntroduction" :class="{'is_lang_zh':  $i18n.locale === 'zh'}">
            {{$t(personageInfo.briefIntroduction)}}</p>
          <p class="user_introduce" :class="{'is_lang_zh':  $i18n.locale === 'zh'}">
            {{ $t(personageInfo.introduce)}}</p>
        </div>
      </div>
    </myDialog>
  </div>
</template>
<script>
import moduleTitle from './components/title.vue'
import myDialog from '@/components/myDialog.vue'
import mySwiper from './components/mySwiper.vue'
import { getNewsList, contactUs } from '@/api/infoRequest.js'
export default {
  components: {
    moduleTitle,
    myDialog,
    mySwiper
  },
  data () {
    return {
      imgList: [
        require('@/assets/img/swiper_imgs/01.jpg'),
        require('@/assets/img/swiper_imgs/02.jpg'),
        require('@/assets/img/swiper_imgs/03.jpg'),
        require('@/assets/img/swiper_imgs/04.jpg'),
        require('@/assets/img/swiper_imgs/05.jpg')
      ],
      ourCultureImgList: [
        require('@/assets/img/swiper_imgs/01.jpg'),
        require('@/assets/img/swiper_imgs/02.jpg'),
        require('@/assets/img/swiper_imgs/03.jpg'),
        require('@/assets/img/swiper_imgs/04.jpg'),
        require('@/assets/img/swiper_imgs/05.jpg'),
        require('@/assets/img/swiper_imgs/06.jpg')
      ],
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
          clickable: true
        },
        autoplay: {
          disableOnInteraction: false,
        },
        loop: true,
        // mousewheel: true,
        // 设置前进后退按钮
        navigation: {
          nextEl: '.button-next',
          prevEl: '.button-prev'
        },
      },
      isImg: false,
      newsList: [],
      joinUsList: [
        {
          position: '招聘.临床研究总监',
          tabs: 'DME（糖尿病黄斑水肿）／（DR）糖尿病视网膜病变／AMD（老年性黄斑变性）',
        },
        {
          position: '招聘.项目经理',
          tabs: '具有医学、药学、生物学专业本科或硕士学历',
        },
        {
          position: '招聘.高级临床监查员',
          tabs: '临床医学、药学、护理学等相关专业，本科及以上学历',
        }
      ],
      // 联系我们表单
      form: {
        name: '',
        Email: '',
        message: ''
      },
      isShow: false, // 人物列表切换
      submitLoading: false, // 提交loading状态位
      scroll: 0, // 滚动距离
      opacity: 1,
      // 人物详细信息
      userInfoList: [
        {
          id: 1,
          imgUrl: require('@/assets/img/index/personage1.png'),
          name: '吴功雄',
          title: '吴功雄职位',
          degree: '医学博士',
          briefIntroduction: '吴功雄简介',
          introduce: '吴功雄介绍'
        },
        {
          id: 2,
          imgUrl: require('@/assets/img/index/personage2.png'),
          name: '张怡琴',
          title: '张怡琴职位',
          degree: '医学博士',
          briefIntroduction: '张怡琴简介',
          introduce: '张怡琴介绍'
        },
        // {
        //   id: 3,
        //   imgUrl: require('@/assets/img/index/personage3.png'),
        //   name: '吴晓明',
        //   title: '吴晓明职位',
        //   degree: '博士',
        //   briefIntroduction: '吴晓明简介',
        //   introduce: '吴晓明介绍'
        // },
        {
          id: 4,
          imgUrl: require('@/assets/img/index/personage4.png'),
          name: '姜文奇',
          title: '姜文奇职位',
          degree: '医学博士',
          briefIntroduction: '姜文奇简介',
          introduce: '姜文奇介绍'
        }
      ],
      personageInfo: {}
    }
  },
  methods: {
    // 跳转资讯详情
    goNewsDetails (id) {
      this.$router.push({ path: '/newsDetails', query: { newsId: id } })
    },
    // 优化大图加载
    onImgLoad () {
      this.isImg = true
    },
    // 跳转招聘详情
    goRecruitInfoPage (i) {
      if (i + 1) {
        this.$router.push({ path: '/recruitInfo', query: { id: i + 1 } })
      } else {
        this.$router.push('/recruitInfo')
      }
    },
    // 跳转资讯列表
    goNewsListPage () {
      this.$router.push('/news')
    },
    // 滚动条事件
    scrollDs () {
      this.scroll = document.documentElement.scrollTop
      if (this.scroll > 0 && this.scroll < 480) {
        const box = this.$refs.bg_content_box
        box.style.top = (this.scroll / 1.7) + 'px'
      } else if (this.scroll === 0) {
        const box = this.$refs.bg_content_box
        box.style.top = '0px'
      }
    },
    // 切换人物列表
    onDisplayDetails (item) {
      this.isShow = true
      this.personageInfo = item
      // if (typeof id === 'number') {
      //   this.personageInfo = this.userInfoList[id - 1]
      // }
    },
    // 获取资讯列表
    async getNewsList () {
      try {
        const res = await getNewsList({
          category: 2,
          pageNum: 1,
          pageSize: 3
        })
        this.newsList = res.data
      } catch (error) {

      }

    },
    // 提交表单
    async onSubmit () {
      if (!this.form.name) {
        return this.message('请填写用户姓名')
      }
      if (!this.form.Email) {
        return this.message('请填写Email地址')
      } else if (!this.verifyEmail(this.form.Email)) {
        return this.message('请填写正确的Email地址')
      }
      if (!this.form.message) {
        return this.message('请填写留言信息')
      }
      this.submitLoading = true
      const res = await contactUs({
        type: '其他问题',
        content: `LW = name:${this.form.name}, email:${this.form.Email}, message:${this.form.message}`
      })
      this.submitLoading = false
      if (res.code === 0) {
        this.message('提交成功')
      } else {
        this.message('提交失败')
      }
      // setTimeout(() => {

      //   this.form = this.$options.data().form
      //   this.submitLoading = false
      // }, 1000)
    },
    // 验证邮箱
    verifyEmail (text) {
      const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
      return reg.test(text)
    },
    // 提示信息
    message (text) {
      this.$notify({
        title: '提示',
        message: text,
        offset: 100,
        position: 'top-left'
      });
    }
  },
  watch: {
    scroll (newVal, oldVal) {
      const box = this.$refs.bg_content_box
      if (newVal > oldVal && newVal > 300 && newVal < 458) {
        // 下滚
        this.opacity = this.opacity === 0 ? 0 : this.opacity / 1.1
      } else if (newVal < oldVal && newVal < 644 && newVal > 300) {
        // 上滚
        this.opacity = this.opacity === 1 ? 1 : this.opacity * 1.1
      } else if (newVal < 300) {
        this.opacity = 1
      } else if (newVal > 644) {
        this.opacity = 0.10
      }
      box.style.opacity = this.opacity
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.scrollDs)
  },
  mounted () {
    // console.log('Current Swiper instance object', this.swiper)
    // this.swiper.slideTo(3, 1000, true)
    window.addEventListener('scroll', this.scrollDs)
  },
  // computed: {
  //   swiper () {
  //     return this.$refs.mySwiper.$swiper
  //   }
  // },
  created () {
    this.getNewsList()

  }
}
</script>
<style lang="less" scoped>
// 任务详情
.user_info_box {
  display: flex;
  height: 100%;
  padding: 30px 70px;
  .info_left {
    width: 182px;
    margin-right: 30px;
    .user_headImg {
      width: 182px;
      height: 214px;
      margin-bottom: 10px;
    }
    .user_title {
      text-align: center;
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
    }
  }
  .info_right {
    display: flex;
    flex-direction: column;
    height: 214px;
    justify-content: center;
    .user_briefIntroduction {
      line-height: 20px;
      margin-bottom: 20px;
    }
    .user_introduce {
      line-height: 20px;
    }
    .is_lang_zh {
      font-size: 14px;
      line-height: 1.5em;
    }
  }
}
.home {
  width: 100%;
  height: 100%;
  .home_bg_box {
    position: relative;
    width: 100%;
    height: 671px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: url('../../assets/img/index/index_zhu.png') no-repeat;
    // background-size: 100% 100%;
    overflow: hidden;
    .index_zhu {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .bg_content_box {
      position: relative;
      width: 580px;
      p {
        text-align: center;
        font-size: 42px;
        letter-spacing: 5px;
        font-weight: 700;
        color: #fff;
        margin-bottom: 37px;
        &:first-child {
          margin-bottom: 10px;
        }
      }
      i {
        display: inline-block;
        width: 100%;
        height: 1px;
        background-color: #fff;
        margin-bottom: 11px;
      }
      span {
        display: inline-block;
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        opacity: 0.7;
        color: #ffffff;
      }
    }
  }

  .aboutus_box {
    width: 100%;
    min-height: 422px;
    background-color: #fff;
    padding: 0 20px 20px;
    .aboutus_content_box {
      // width: 1200px;
      width: var(--width);
      height: 100%;
      margin: 0 auto;
      padding-top: 42px;

      .aboutus_content {
        display: flex;
        .aboutus_content_left {
          width: 566px;
          height: 265px;
          flex-shrink: 0;
          margin-right: 26px;
          // background: url('../../assets/img/index/aboutUs.jpg') no-repeat;
          // background-size: 100% 100%;
        }
        .aboutus_content_right {
          flex: 1;
          h3 {
            font-size: 16px;
            font-weight: 600;
            color: #000000;
            text-align: center;
            margin-bottom: 12px;
          }
          p {
            font-size: 14px;
            line-height: 20px;
          }
          .first_child {
            margin-bottom: 10px;
          }
          .aboutus_lang_zh {
            line-height: 2em;
            letter-spacing: 2px;
          }
        }
      }
    }
  }

  .user_box {
    width: 100%;
    min-height: 518px;
    // height: 800px;
    background-color: #fff;
    transition: height 0.3s;
    .user_content_box {
      position: relative;
      min-height: 518px;
      width: var(--width);
      margin: 0 auto;
      padding: 40px 0 50px;
      overflow: hidden;
      // 人物列表
      .user_content_list {
        display: flex;
        justify-content: space-around;
        li {
          position: relative;
          top: 0;
          width: 23%;
          padding-bottom: 10px;
          border-radius: 3px;
          box-shadow: -1px 2px 10px 0 #c1c1c1;
          transition: all 0.3s;
          &:last-child {
            margin-right: 0;
          }
          &:hover {
            top: -6px;
            // box-shadow: -1px 2px 10px 0 #c1c1c1, -1px 33px 36px -26px #c1c1c1;
            // box-shadow: -1px 2px 10px 0 #c1c1c1, -1px 20px 15px -10px #c1c1c1;
            box-shadow: -1px 2px 10px 0 #c1c1c1, -1px 20px 20px -15px #c1c1c1;
          }
          img {
            width: 100%;
            height: 300px;
            object-fit: cover;
          }
          .user_name {
            font-size: 18px;
            margin-top: 20px;
            span {
              font-size: 14px;
              color: #666666;
            }
          }
          .userlist_itemtitle {
            font-size: 14px;
            color: #666666;
          }
          p {
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-break: break-all;
            line-height: 1.4em;
          }
          .iconfont {
            font-size: 20px;
            color: #499e39;
            margin-left: 20px;
            &:hover {
              border-radius: 50%;
              background: linear-gradient(145deg, #d3d4d7, #fafdff);
              box-shadow: 2px 2px 4px #c7c9cb, -2px -2px 4px #ffffff;
            }
          }
          .user_lang_zh {
            line-height: 1.5em;
          }
        }
      }
    }
  }

  .strategy_box {
    width: 100%;
    min-height: 311px;
    background-color: #eaecef;
    padding-bottom: 20px;
    .strategy_content_box {
      width: var(--width);
      height: 100%;
      // padding-top: 40px;
      padding: 40px 20px 0;
      margin: 0 auto;
      background-color: #eaecef;
      .strategy_content {
        display: flex;
        justify-content: space-between;
        .strategy_content_left {
          width: 55%;
          font-size: 14px;
          flex-shrink: 0;
          h4 {
            font-size: 16px;
            font-weight: 700;
          }
          .bottom {
            margin-bottom: 8px;
          }
          .strategy_content_left_list {
            li {
              list-style-type: disc;
              margin-left: 36px;
            }
          }
        }

        .strategy_content_right {
          width: 40%;
          flex-shrink: 0;
          font-size: 14px;
          padding-right: 20px;
          h4 {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 8px;
          }
          p {
            line-height: 25px;
          }
        }
      }
    }
  }

  .focus_box {
    width: 100%;
    height: 657px;
    background-color: #eaecef;
    .focus_content_box {
      width: var(--width);
      height: 100%;
      padding-top: 40px;
      margin: 0 auto;
      background-color: #eaecef;
      .focus_content {
        .focus_content_list {
          display: flex;
          flex-wrap: wrap;
          .padding {
            padding: 0 32px;
          }
          .list_item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            height: 168px;
            font-size: 14px;
            background-color: #fff;
            h4 {
              font-weight: 600;
              margin-bottom: 8px;
              font-size: 16px;
            }
            .list_content {
              width: 90%;
              .text_list {
                padding-left: 36px;
                li {
                  margin-bottom: 8px;
                  list-style-type: disc;
                }
              }
            }

            img {
              width: 100%;
              height: 100%;
            }

            .list_content_last {
              width: 80%;
              h4 {
                font-weight: 600;
                margin-bottom: 8px;
                font-size: 16px;
              }
              ul {
                display: flex;
                flex-wrap: wrap;
                padding-left: 36px;
                li {
                  width: 50%;
                  margin-bottom: 8px;
                  list-style-type: disc;
                }
              }
            }
          }
        }

        .focus_content_foot {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 208px;
          padding: 0 20px;
          color: #fff;
          background-color: #254289;
          .foot_box {
            width: 90%;
            font-size: 14px;
            h4 {
              font-size: 16px;
              font-weight: 600;
              // line-height: 20px;
              margin-bottom: 8px;
            }
            p {
              line-height: 20px;
            }
            .foot_lang_zh {
              line-height: 2em;
            }
          }
        }
      }
    }
  }

  .our_culture {
    width: 100%;
    height: 453px;
    background-color: #fff;
    .our_culture_content {
      width: var(--width);
      // height: 100%;
      padding-top: 40px;
      margin: 0 auto;
      .our_culture_content_box {
        display: flex;
        width: 100%;
        height: calc(100% - 123px);
        // align-items: center;
        justify-content: space-around;
        .our_culture_content_left,
        .our_culture_content_right {
          width: 45%;
          height: 100%;
          padding-left: 20px;
          img {
            width: 100%;
          }
          // p {
          //   text-align: center;
          //   font-size: 14px;
          //   margin: 25px 0;
          // }
        }
        .my_swiper_box {
          width: 100%;
          height: 260px;
        }
        .our_culture_content_right {
          width: 45%;
          height: 100%;
          padding: 0 20px 0 0;
        }
      }
    }
  }

  .news_joinus_box {
    width: 100%;
    min-height: 400px;
    padding-bottom: 20px;
    background-color: #eaecef;
    .news_joinus_content_box {
      width: var(--width);
      min-height: 400px;

      margin: 0 auto;
      background-color: #eaecef;
      .news_box,
      .joinus_box {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        height: 100%;
        padding-top: 50px;
        ul {
          li {
            // margin-bottom: 16px;
            &:last-child {
              margin-bottom: 0px;
            }
            h4 {
              font-size: 14px;
              // font-weight: 600;
              // Arial
              margin-bottom: 4px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              cursor: pointer;
              &:hover {
                text-decoration: underline;
              }
            }
            p {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              word-break: break-all;
              -webkit-box-orient: vertical;
              color: #9195a3;
            }
          }
        }
      }
      .news_box {
        ul {
          padding: 0 30px;
          li {
            font-size: 12px;
            padding: 10px 20px;
            margin-bottom: 10px;
            background-color: #fff;
            border-radius: 5px;
            &:hover {
              box-shadow: 6px 6px 7px 0 #c7c9cb;
              transition: all 0.3s;
            }
          }
        }
      }
      .joinus_box {
        ul {
          padding: 0 50px;
          li {
            font-size: 12px;
            padding: 10px 20px;
            background-color: #fff;
            border-radius: 5px;
            margin-bottom: 10px;
            min-height: 75px;
            &:hover {
              box-shadow: 6px 6px 7px 0 #c7c9cb;
              transition: all 0.3s;
            }
            .Job_profile {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 8px;
              padding-right: 20px;
              color: #9195a3;
              span {
                font-size: 14px;
                padding: 0 10px;
                border-right: 1px solid #969696;
                &:last-child {
                  border: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .contact_us_box {
    width: 100%;
    height: 542px;
    background-color: #fff;
    .contact_us_content_box {
      width: var(--width);
      height: 100%;
      margin: 0 auto;
      padding-top: 40px;
      .contact_us_content {
        display: flex;
        font-size: 14px;
        .contact_us_content_left {
          width: 50%;
          padding: 0 50px;
          h4 {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 16px;
          }
          input,
          textarea {
            width: 100%;
            height: 40px;
            line-height: 40px;
            margin-bottom: 16px;
            color: #000;
            font-weight: 700;
            padding-left: 10px;
            border-radius: 3px;
            font-size: 14px;
            border: 1px solid #969696;
          }
          /deep/.el-textarea__inner:focus {
            border-color: #969696;
          }
          /deep/.el-textarea__inner::placeholder {
            color: rgb(117, 117, 117);
          }
          /deep/.el-textarea__inner {
            border-color: #969696;
            color: #000;
            font-weight: 700;
            // font-size: 14px;
          }
          .el-button {
            width: 100%;
            height: 40px;
            margin-top: 16px;
            font-size: 14px;
            font-weight: 600;
          }
          /deep/.el-button--info {
            background-color: #c7c7c7;
            border-color: #c7c7c7;
          }
          /deep/.el-button--info:hover {
            background: #a6a9ad;
            border-color: #a6a9ad;
            color: #fff;
          }
          .explain_text {
            margin-top: 16px;
            color: #616161;
          }
        }
        .contact_us_content_right {
          width: 50%;
          padding: 0 20px;
          font-size: 14px;
          h4 {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 8px;
          }
          .address_text {
            margin-bottom: 16px;
            line-height: 20px;
          }
          .email {
            margin-bottom: 24px;
          }
          p {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}
</style>