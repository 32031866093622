export default {
  '标语1': 'CREATING CURES AND ',
  '标语2': 'VALUES TO PATIENTS',
  '副标语': "We're dedicated to innovation!",
  '关于我们': 'ABOUT US',
  '关于远森制药': 'About Longwood Pharmaceuticals',
  '公司简介': ' Longwood Pharmaceuticals is an innovative, research-based startup biopharmaceutical company based in China and the U.S, established by a world-class team of veteran scientists in drug discovery. We are committed to developing and commercializing first-in-class and best-in-class medicine to treat vascular diseases in China and worldwide',
  '公司介绍': 'The company is headquartered in Beijing and has starting capital of $30 million. We are rapidly expanding into a fully integrated biopharmaceutical company. We have built our internal R&D center to advance our discovery pipeline, robust clinical development, and business operation team and are considering setting up our manufacturing facilities in China. We have also established a highly specialized commercial team to support the marketing of our innovative products in China',
  '成果展示': 'OUR FOCUS',
  '专注于': 'Focus on',
  '专注列表1': 'Diabetic macular edema (DME)',
  '专注列表2': 'Wet age-related macular degeneration (wet AMD)',
  '专注列表3': 'Macular edema following retinal retinal vein occlusion (ME-RVO)',
  '药物靶向': 'The drug targets',
  '药物列表1': 'Plasma Kallikrein (PK)',
  '药物列表2': 'Rho/Rho Kinase (ROCK)',
  '药物列表3': 'Cytokines',
  '药物列表4': 'Complement (H,C3,C5)',
  '药物列表5': 'Intergrin',
  '眼科学': 'Ophthalmology',
  '成果段落1': 'Anti-VEGF injections remains a major unmet need for the treatment of retinal vascular diseases.',
  '成果段落2': 'We are investigating mechanism-based novel drug targets and developing small molecule inhibitors inVEGF-independent pathways.',
  '成果段落3': 'We partner with academia,biotech and pharmaceutical companies around the world,start with a license-in model and will gradually expand to an internal discovery&development platform.',
  '成果段落4': 'We are currently advancing a pipeline of novel oral small molecules for retinal vascular diseases',
  '管理层': 'SENIOR MANAGEMENT',
  '医学博士': 'M.D., Ph.D.',
  '博士': 'Ph.D.',
  '吴功雄': 'George Wu, ',
  '吴功雄职位': 'Chief Executive Officer',
  '吴功雄简介': 'He received his M.D. from Guangzhou Medical University, China; his Ph.D. from Peking Union Medical College (PUMC) & Chinese Academy of Medical Sciences & Harvard Medical School',
  '吴功雄介绍': 'Dr. Wu is founder and Chief Executive Officer of Longwood Pharmaceuticals. Previously he was an academic research veteran at Harvard Medical School with more than 10 years experience of molecular pathway and drug target finding in neuroscience and vascular biology. He founded and served as CEO of Mass Medical International Corp (MMI) with a proven track record of successful team building, management, and business development. During his tenure as the CEO of MMI, the company grew from startup to a global organization of more than 300 employees. He is also a partner of Fosun Capital Group, an international financial service company in the healthcare industry',
  '张怡琴': 'Yiqin Zhang, ',
  '张怡琴职位': 'Chief Medical Officer',
  '张怡琴简介': 'Dr. Zhang received her M.D. from Jiangxi Medical School, Jiangxi, China, her Ph.D. in Neural-Ophthalmology from Lund University, Lund, Sweden, and her Post-Doctoral Fellowship from Harvard Medical School. She also received her M.S. in Neurology from Sun Yat-sen University Zhongshan Medical School',
  '张怡琴介绍': 'Dr. Zhang is a neural-ophthalmologist and physician-scientist with over 25 years of clinical and drug development experience. She was co-owner and President of Myadvice Consultant Group, where she provided supervision for clinical development strategy, clinical trial design, and regulatory submission. Dr. Zhang worked for Novartis, where she served as the small molecule pipeline team leader for delivering oral and topical VEGF and complement C3 inhibitors to the clinic through line function. Before her pharmaceutical industry experience, Dr. Zhang was a senior ophthalmologist and physician-in-charge in Zhongshan Eye Hospital, Guangzhou, China. She is an inventor or author on more than 20 patents and publications in the field of Ophthalmology',
  '吴晓明': 'Xiaoming Wu, ',
  '吴晓明职位': 'The Head of CMC',
  '吴晓明简介': 'Dr. Wu received his Ph.D. in Organic Chemistry Synthesis from Wayne State University, Michigan, USA and his M.S. in Organic Chemistry Synthesis from Hong Kong University of Science and Technology, Hong Kong',
  '吴晓明介绍': 'Dr. Xiaoming Wu has worked for Anikon Drug Research and Development, New Jersey, U.S.A., Zhejiang Kyushu Pharmaceutical, and Wuhan Kerry Kangning Bio Co. He has over 20 years of practical experience in synthetic chemistry of small molecules, carbohydrates and peptides; experienced in process development/optimization, analytical development and validation of small molecule APIs and intermediates, from laboratory studies to pilot plant scale-up and commercial production',
  '姜文奇': 'Wenqi Jiang, ',
  '姜文奇职位': 'Chief Scientific Officer',
  '姜文奇简介': 'Dr. Jiang graduated from Shanghai Medical University in clinical oncology and was Associate Professor at M.D. Anderson Cancer Center and other institutions in the United States',
  '姜文奇介绍': 'Dr. Jiang has served as Deputy Director of the Center for Cancer Prevention and Treatment of Zhongshan University, Vice President of the Cancer Hospital of Zhongshan University and Director of the Department of Internal Medicine, Vice Director of the Institute of Clinical Pharmacology of Zhongshan University and Director of the Academic Committee of the National Center for Clinical Trials of New Antitumor Drugs',
  '商业战略模块': 'OUR BUSINESS MODEL & STRATEGY',
  '商业模式': 'BUSINESS MODE',
  '商业列表1': 'In-license and internal discovery in parallel',
  '商业列表2': ' In-license of pre-clinical or early-phase clinical assets in China or/and global rights',
  '商业列表3': ' Deliver the complete clinical proof of concept study for out-license or go further for China-based commercialization with the maximized value chain',
  '商业列表4': 'Internal discovery by a top-notch international R&D team',
  '战略规划': 'STRATEGY',
  '战略列表1': 'De-risking solutions for pipelines',
  '战略列表2': 'Differentiated from other Chinese biotech competitors, focused on vascular retina diseases, expand to cardiovascular/ metabolism(CVM) disorders and cancer',
  '企业文化': 'OUR CULTURE',
  '诚信创新': 'Integrity and Innovation',
  '责任效率': 'Responsibility and Efficiency',
  '最新资讯': 'NEWS',
  '加入我们': 'JOIN US',
  '联系我们': 'CONTACT US',
  '写信': 'Drop us a line!',
  '写信提交': 'Send',
  '隐私说明': 'This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.',
  '公司名字': 'Longwood Pharmaceuticals',
  '公司地址': ' Building 29, Fengchuang Science and Technology Park, No. 18, Kechuang 13th Street, Beijing Economic and Technological Development Zone, Bejing, China',
  '工作时间': 'Hours',
  '工作时间详情': 'Monday to Friday 9:00 am-18:00 pm',
  '公司电话': 'TEL: （010）67864223',
  '版权所有': 'Copyright © 2021 Longwood Pharmaceuticals - All Rights Reserved.'
}