export default {
  '标语1': '专注于眼科领域的',
  '标语2': '创新药物研发平台',
  '副标语': '我们致力于创新！',
  '关于我们': '关于我们',
  '关于远森制药': '关于远森制药',
  '公司简介': ' 远森制药是一家总部位于中国的创新研发初创生物制药公司，由世界一流的药物研发资深科学家团队创建。我们致力于在中国和全球范围内开发和商业化全新靶点一类的治疗血管疾病的药物',
  '公司介绍': '该公司总部位于北京，拥有丰厚的初始资本。我们正在迅速发展成为一家完全一体化的生物制药公司。我们已经建立了我们的内部R&D中心，以推进我们的发现管道、强大的临床开发和业务运营团队，并正在考虑在中国建立我们的生产设施。我们还建立了一支高度专业化的商业团队，以支持我们创新产品在中国的营销。',
  '成果展示': '成果展示',
  '专注于': '专注于',
  '专注列表1': '糖尿病性黄斑水肿（DME）',
  '专注列表2': '湿性老年性黄斑变性（湿性AMD）',
  '专注列表3': '视网膜静脉阻塞后黄斑水肿（ME-RVO）',
  '药物靶向': '药物靶向',
  '药物列表1': '血浆激肽释放酶（PK）',
  '药物列表2': 'Rho/Rho激酶（ROCK）',
  '药物列表3': '细胞因子',
  '药物列表4': '补体（H,C3,C5）',
  '药物列表5': '整合素',
  '眼科学': '眼科学',
  '成果段落1': '抗血管内皮生长因子注射仍然是视网膜血管疾病治疗中一个主要的未满足需求。',
  '成果段落2': '我们正在研究基于机制的新药物靶点，并开发不依赖于EGF途径的小分子抑制剂。',
  '成果段落3': '我们与世界各地的学术界、生物技术和制药公司合作，从许可模式开始，并将逐步扩展到内部发现和开发平台。',
  '成果段落4': '我们目前正在推进一条用于视网膜血管疾病的新型口服小分子的管道',
  '管理层': '高级管理层',
  '医学博士': 'M.D., Ph.D.',
  '博士': 'Ph.D.',
  '吴功雄': '吴功雄 ',
  '吴功雄职位': '首席执行官',
  '吴功雄简介': '他在中国广州医科大学获得医学博士学位；博士毕业于北京协和医学院&中国医学科学院&哈佛医学院。',
  '吴功雄介绍': '吴博士是远森制药的创始人兼首席执行官。此前，他是哈佛医学院的学术研究资深人士，在神经科学和血管生物学领域拥有超过10年的分子途径和药物靶点发现经验。他创立了麻省医疗国际公司，并担任首席执行官，在团队建设、管理和业务发展方面有着良好的记录。在他担任麻省医疗国际公司首席执行官期间，该公司从初创公司发展成为拥有300多名员工的全球性组织。他还是医疗保健行业的国际金融服务公司复星资本集团的合伙人。',
  '张怡琴': '张怡琴 ',
  '张怡琴职位': '首席医疗官',
  '张怡琴简介': '张博士于中国江西江西医学院取得医学博士学位，于瑞典隆德大学取得神经眼科博士学位，并于哈佛医学院取得博士后研究金。她还获得了中山大学中山医学院神经病学硕士学位。',
  '张怡琴介绍': '张博士为神经眼科医生及内科医生兼科学家，拥有超过25年的临床及药物开发经验。她是Myadvice咨询集团的共同所有人和总裁，负责临床开发策略、临床试验设计和法规提交的监督工作。张博士在诺华公司工作，担任小分子管道团队负责人，负责通过管路功能将口服和局部血管内皮生长因子和补体C3抑制剂输送至临床。在从事制药行业之前，张博士为中国广州中山眼科医院的高级眼科医生兼主治医师。她是眼科领域20多项专利和出版物的发明者或作者。',
  '吴晓明': '吴晓明 ',
  '吴晓明职位': '项目管理总监',
  '吴晓明简介': '吴博士获美国密歇根州立大学有机化学合成博士学位及香港科技大学有机化学合成硕士学位',
  '吴晓明介绍': '吴博士曾就职于美国新泽西Anikon药物研发公司、浙江九州制药公司和武汉凯瑞康宁生物有限公司，在小分子、碳水化合物和肽的合成化学方面拥有超过20年的实践经验；具有小分子原料药和中间体的工艺开发/优化、分析开发和验证经验，从实验室研究到中试放大和商业生产。',
  '姜文奇': '姜文奇 ',
  '姜文奇职位': '首席科学官',
  '姜文奇简介': '姜博士毕业于上海医科大学临床肿瘤学专业，曾任安德森癌症中心医学博士及美国其他机构副教授',
  '姜文奇介绍': '姜博士历任中山大学肿瘤防治中心副主任、中山大学肿瘤医院副院长兼内科主任、中山大学临床药理研究所副所长、国家抗肿瘤新药临床试验中心学术委员会主任。',
  '商业战略模块': '我们的商业模式和战略规划',
  '商业模式': '商业模式',
  '商业列表1': '通过技术许可和内部发现并行',
  '商业列表2': ' 在中国和全球授权新药的临床前或早期临床资产的许可',
  '商业列表3': ' 提供完整的临床概念验证研究，以获得许可，或通过价值链最大化，进一步在中国实现商业化',
  '商业列表4': '顶级国际R&D团队的内部发现',
  '战略规划': '战略规划',
  '战略列表1': '管道去风险解决方案',
  '战略列表2': '区别于其他中国生物技术竞争对手，专注于血管性视网膜疾病，扩展到心血管/代谢（CVM）疾病和癌症',
  '企业文化': '企业文化',
  '诚信创新': '',
  '责任效率': '',
  '最新资讯': '最新资讯',
  '加入我们': '加入我们',
  '联系我们': '联系我们',
  '写信': '给我们写封信！',
  '写信提交': '发送',
  '隐私说明': '本网站受reCAPTCHA保护，适用谷歌隐私政策和服务条款。',
  '公司名字': '远森制药',
  '公司地址': '北京市北京经济技术开发区科创十三街18号院29号楼6层603室(北京自贸试验区高端产业片区亦庄组团)',
  '工作时间': '工作时间',
  '工作时间详情': '周一至周五 上午9:00-下午18:00',
  '公司电话': '电话: （010）67864223',
  '版权所有': '版权所有© 2021 远森制药——保留所有权利。'
}